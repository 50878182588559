import React, { useEffect, useState } from "react";
import "./Trendy.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa";
import {
  fetchProductList,
  fetchProductType,
} from "../../../Redux/productSlice";
import ProductSkeleton from "../../Skeleton/ProductSkeleton";

const Trendy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products, loading } = useSelector((state) => state.product);

  const [activeTab, setActiveTab] = useState();
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    const params = {
      limit: 8,
      skip: 0,
    };
  
    dispatch(fetchProductList(params));

    dispatch(fetchProductType({ limit: 5 })).then((response) => {
      const newProductTypes = response.payload.data;
      setFullList(newProductTypes);
    });
  }, [dispatch]);

  const handleTabClick = (type) => {
    setActiveTab(type);
    let params = {
      limit: 8,
      skip: 0,
    };
    params.product_type = type;
    dispatch(fetchProductList(params));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="trendyProducts">
        <h2>
          Our Trendy <span>Products</span>
        </h2>
        <div className="trendyTabs">
          <div className="tabs">
            {fullList.map((e, index) => (
              <p
                key={index}
                onClick={() => handleTabClick(e.product_type)}
                className={activeTab === e.product_type ? "active" : ""}
              >
                {e.product_type || ""}
              </p>
            ))}
          </div>
          <div className="trendyTabContent">
            <div className="trendyMainContainer">
              {loading ? (
                <ProductSkeleton />
              ) : (
                products.map((product) => (
                  <div className="trendyProductContainer" key={product._id}>
                    <div className="trendyProductImages">
                      <Link
                        to={`/product/${product.id || product._id}`}
                        state={{ productId: product._id }}
                        onClick={scrollToTop}
                      >
                        <img
                          src={product.images[0]?.src}
                          alt={product.title}
                          // className="trendyProduct_front"
                        />
                        {product?.images[1] && product?.images[1]?.src && (
                          <img
                            src={product.images[1]?.src}
                            alt={product.title}
                            className="sdProduct_back"
                          />
                        )}
                      </Link>
                      <h4
                        onClick={() =>
                          navigate(`/product/${product.id || product._id}`)
                        }
                      >
                        Add to Cart
                      </h4>
                    </div>
                    <div
                      className="trendyProductImagesCart"
                      // onClick={() => dispatch(addToCart(product))}
                    >
                      <FaCartPlus />
                    </div>

                    <div className="trendyProductInfo">
                      <div className="trendyProductCategoryWishlist">
                        <p>{product.product_type}</p>
                      </div>
                      <div className="trendyProductNameInfo">
                        <Link
                          to={`/product/${product.id || product._id}`}
                          state={{ productId: product._id }}
                          onClick={scrollToTop}
                        >
                          <h5>{product.title}</h5>
                        </Link>

                        <p>£{product.variants[0].price}</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="discoverMore">
          <Link to="/shop" onClick={scrollToTop}>
            <p>Discover More</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Trendy;
