import { configureStore } from '@reduxjs/toolkit';
import cartSlice from '../Redux/cartSlice';
import authSlice from '../Redux/authSlice'
import productSlice from '../Redux/productSlice';

const store = configureStore({
  reducer: {
    cart: cartSlice,
    auth: authSlice,
    product: productSlice,
  },
});

export default store;
