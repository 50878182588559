import React, { useEffect } from "react";
import "./RelatedProducts.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductList } from "../../../Redux/productSlice";
import { Link, useNavigate } from "react-router-dom";

const RelatedProducts = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products } = useSelector((state) => state.product);

  const limit = 12;

  useEffect(() => {
    let params = {
      limit,
      skip: 0,
    };
    if (data?.product_type) params.product_type = data?.product_type;
    if (data?.product_type) {
      dispatch(fetchProductList(params));
    }
  }, [dispatch, data?.product_type]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="relatedProductSection">
        <div className="relatedProducts">
          <h2>
            RELATED <span>PRODUCTS</span>
          </h2>
        </div>
        <div className="relatedProductSlider">
          <div className="swiper-button image-swiper-button-next">
            <IoIosArrowForward />
          </div>
          <div className="swiper-button image-swiper-button-prev">
            <IoIosArrowBack />
          </div>
          <Swiper
            slidesPerView={4}
            slidesPerGroup={4}
            spaceBetween={30}
            loop={true}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            modules={[Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 14,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 30,
              },
            }}
          >
            {products.map((product) => {
              return (
                <SwiperSlide key={product.productID}>
                  <div className="rpContainer">
                    <div className="rpImages" onClick={scrollToTop}>
                      <Link
                        to={`/product/${product.id || product._id}`}
                        state={{ productId: product._id }}
                        onClick={scrollToTop}
                      >
                        <img
                          src={product.images[0]?.src}
                          alt={product.title}
                          // className="trendyProduct_front"
                        />
                        {product?.images[1] && product?.images[1]?.src && (
                          <img
                            src={product.images[1]?.src}
                            alt={product.title}
                            className="sdProduct_back"
                          />
                        )}
                      </Link>
                      <h4
                        onClick={() =>
                          navigate(`/product/${product.id || product._id}`)
                        }
                      >
                        Add to Cart
                      </h4>
                    </div>

                    <div className="relatedProductInfo">
                      <div className="rpCategoryWishlist">
                        <p>{product?.product_type}</p>
                      </div>
                      <div className="productNameInfo">
                        <h5
                          onClick={() =>
                            navigate(`/product/${product.id || product._id}`)
                          }
                        >
                          {product?.title}
                        </h5>
                        <p>£{product.variants[0].price}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
