import React, { useEffect, useState } from "react";
import "./ShoppingCart.css";
import { useSelector, useDispatch } from "react-redux";
import {
  productListOfCart,
  removeItemFromCart,
  updateCurrentCart,
  updateItemFromCart,
} from "../../Redux/cartSlice";

import { MdOutlineClose } from "react-icons/md";

import { Link } from "react-router-dom";

import success from "../../Assets/success.png";
import CartSkeleton from "./CartSkeleton";
import SnackbarComponent from "../Snackbar";
import useSnackbar from "../../hooks/useSnackbar";

const ShoppingCart = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const cartItems = useSelector((state) => state.cart.items);
  const loading = useSelector((state) => state.cart.listLoader);
  const totalPrice = useSelector((state) => state.cart.totalAmount);

  const dispatch = useDispatch();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState("cartTab1");
  const [payments, setPayments] = useState(false);

  useEffect(() => {
    if (isAuthenticated) dispatch(productListOfCart());
  }, [dispatch, isAuthenticated]);

  const handleTabClick = (tab) => {
    if (tab === "cartTab1" || cartItems.length > 0) {
      setActiveTab(tab);
    }
  };

  const handleQuantityChange = (item, quantity) => {
    dispatch(updateCurrentCart({ item: item, quantity: quantity }));
    if (quantity >= 1 && quantity <= item?.variantId?.inventory_quantity) {
      dispatch(updateItemFromCart({ _id: item._id, quantity: quantity }));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const currentDate = new Date();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const orderNumber = Math.floor(Math.random() * 100000);

  const [selectedPayment, setSelectedPayment] = useState(
    "Direct Bank Transfer"
  );

  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const getSubTotal = (item) => {
    let price = item.variantId?.price ? Number(item.variantId?.price) : 0;
    return `£` + item.quantity * price;
  };

  const handleRemoveCart = (item) => {
    dispatch(removeItemFromCart(item._id))
      .then(({ payload }) => {
        showSnackbar(
          payload.message || "Item removed from cart successfully!",
          payload.success ? "success" : "error"
        );
        dispatch(productListOfCart());
      })
      .catch((error) => {
        showSnackbar(error.message || "Failed to remove from cart!", "error");
      });
  };

  return (
    <div>
      <div className="shoppingCartSection">
        <h2>Cart</h2>

        <div className="shoppingCartTabsContainer">
          <div className={`shoppingCartTabs £{activeTab}`}>
            <button
              className={activeTab === "cartTab1" ? "active" : ""}
              onClick={() => {
                handleTabClick("cartTab1");
                setPayments(false);
              }}
            >
              <div className="shoppingCartTabsNumber">
                <h3>01</h3>
                <div className="shoppingCartTabsHeading">
                  <h3>Shopping Bag</h3>
                  <p>Manage Your Items List</p>
                </div>
              </div>
            </button>
            <button
              className={activeTab === "cartTab2" ? "active" : ""}
              onClick={() => {
                handleTabClick("cartTab2");
                setPayments(false);
              }}
              disabled={cartItems.length === 0}
            >
              <div className="shoppingCartTabsNumber">
                <h3>02</h3>
                <div className="shoppingCartTabsHeading">
                  <h3>Shipping and Checkout</h3>
                  <p>Checkout Your Items List</p>
                </div>
              </div>
            </button>
            <button
              className={activeTab === "cartTab3" ? "active" : ""}
              onClick={() => {
                handleTabClick("cartTab3");
              }}
              disabled={cartItems.length === 0 || payments === false}
            >
              <div className="shoppingCartTabsNumber">
                <h3>03</h3>
                <div className="shoppingCartTabsHeading">
                  <h3>Confirmation</h3>
                  <p>Review And Submit Your Order</p>
                </div>
              </div>
            </button>
          </div>
          {loading ? (
            <CartSkeleton />
          ) : (
            <div className="shoppingCartTabsContent">
              {/* tab1 */}
              {activeTab === "cartTab1" && (
                <div className="shoppingBagSection">
                  <div className="shoppingBagTableSection">
                    {/* For Desktop Devices */}
                    <table className="shoppingBagTable">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th />
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.length > 0 ? (
                          cartItems.map((item) => (
                            <tr key={item._id}>
                              <td data-label="Product">
                                <div className="shoppingBagTableImg">
                                  <Link to="/product" onClick={scrollToTop}>
                                    <img
                                      src={item.variantImage?.src}
                                      alt={item.productId?.title}
                                    />
                                  </Link>
                                </div>
                              </td>
                              <td data-label="">
                                <div className="shoppingBagTableProductDetail">
                                  <Link to="/product" onClick={scrollToTop}>
                                    <h4>{item.productId?.title}</h4>
                                    {item.productId?.variants?.length > 1 && (
                                      <p>Variant: {item.variantId?.title}</p>
                                    )}
                                  </Link>
                                </div>
                              </td>
                              <td
                                data-label="Price"
                                style={{ textAlign: "center" }}
                              >
                                £{item.variantId?.price}
                              </td>
                              <td data-label="Quantity">
                                <div className="ShoppingBagTableQuantity">
                                  <button
                                    onClick={() =>
                                      handleQuantityChange(
                                        item,
                                        item.quantity - 1
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    min="1"
                                    max="20"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        item,
                                        parseInt(e.target.value)
                                      )
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      handleQuantityChange(
                                        item,
                                        item.quantity + 1
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td data-label="Subtotal">
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "500",
                                  }}
                                >
                                  {getSubTotal(item)}
                                </p>
                              </td>
                              <td data-label="" style={{ cursor: "pointer" }}>
                                <MdOutlineClose
                                  onClick={() => handleRemoveCart(item)}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <div className="shoppingCartEmpty">
                                <span>Your cart is empty!</span>
                                <Link to="/shop" onClick={scrollToTop}>
                                  <button>Shop Now</button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <th
                          colSpan="6"
                          className="shopCartFooter"
                          style={{
                            borderBottom: "none",
                            padding: "20px 0px",
                          }}
                        >
                          {cartItems.length > 0 && (
                            <div className="shopCartFooterContainer">
                              <form>
                                <input type="text" placeholder="Coupon Code" />
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  Apply Coupon
                                </button>
                              </form>
                            </div>
                          )}
                        </th>
                      </tfoot>
                    </table>

                    {/* For Mobile devices */}

                    <div className="shoppingBagTableMobile">
                      {cartItems.length > 0 ? (
                        <>
                          {cartItems.map((item) => (
                            <div key={item.productID}>
                              <div className="shoppingBagTableMobileItems">
                                <div className="shoppingBagTableMobileItemsImg">
                                  <Link to="/product" onClick={scrollToTop}>
                                    <img
                                      src={item.variantImage?.src}
                                      alt={item.productId?.title}
                                    />
                                  </Link>
                                </div>
                                <div className="shoppingBagTableMobileItemsDetail">
                                  <div className="shoppingBagTableMobileItemsDetailMain">
                                    <Link to="/product" onClick={scrollToTop}>
                                      <h4>{item.productId?.title}</h4>
                                      <p>
                                        {item.productId?.variants?.length >
                                          1 && (
                                          <p>
                                            Variant: {item.variantId?.title}
                                          </p>
                                        )}
                                      </p>
                                    </Link>
                                    <div className="shoppingBagTableMobileQuantity">
                                      <button
                                        onClick={() =>
                                          handleQuantityChange(
                                            item,
                                            item.quantity - 1
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                      <input
                                        type="text"
                                        min="1"
                                        max="20"
                                        value={item.quantity}
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            item,
                                            parseInt(e.target.value)
                                          )
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          handleQuantityChange(
                                            item,
                                            item.quantity + 1
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <span>£{item.variantId?.price}</span>
                                  </div>
                                  <div className="shoppingBagTableMobileItemsDetailTotal">
                                    <MdOutlineClose
                                      size={20}
                                      onClick={() =>
                                        dispatch(handleRemoveCart(item))
                                      }
                                    />
                                    <p>
                                      £{item.quantity * item.variantId?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="shopCartFooter">
                            <div className="shopCartFooterContainer">
                              <form>
                                <input type="text" placeholder="Coupon Code" />
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  Apply Coupon
                                </button>
                              </form>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="shoppingCartEmpty">
                          <span>Your cart is empty!</span>
                          <Link to="/shop" onClick={scrollToTop}>
                            <button>Shop Now</button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="shoppingBagTotal">
                    <h3>Cart Totals</h3>
                    <table className="shoppingBagTotalTable">
                      <tbody>
                        <tr>
                          <th>Subtotal</th>
                          <td>£{totalPrice.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <th>Shipping</th>
                          <td>
                            <div className="shoppingBagTotalTableCheck">
                              <p>£{(totalPrice === 0 ? 0 : 5).toFixed(2)}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>VAT</th>
                          <td>£{(totalPrice === 0 ? 0 : 11).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <th>Total</th>
                          <td>
                            £
                            {(totalPrice === 0 ? 0 : totalPrice + 16).toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      onClick={() => {
                        handleTabClick("cartTab2");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      disabled={cartItems.length === 0}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </div>
              )}

              {/* tab2 */}
              {activeTab === "cartTab2" && (
                <div className="checkoutSection">
                  <div className="checkoutDetailsSection">
                    <h4>Billing Details</h4>
                    <div className="checkoutDetailsForm">
                      <form>
                        <div className="checkoutDetailsFormRow">
                          <input type="text" placeholder="First Name" />
                          <input type="text" placeholder="Last Name" />
                        </div>
                        <input
                          type="text"
                          placeholder="Company Name (optional)"
                        />
                        <select name="country" id="country">
                          <option value="Country / Region" selected disabled>
                            Country / Region
                          </option>
                          <option value="India">India</option>
                          <option value="Canada">Canada</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="Turkey">Turkey</option>
                        </select>
                        <input type="text" placeholder="Street Address*" />
                        <input type="text" placeholder="" />
                        <input type="text" placeholder="Town / City *" />
                        <input type="text" placeholder="Postcode / ZIP *" />
                        <input type="text" placeholder="Phone *" />
                        <input type="mail" placeholder="Your Mail *" />
                        <textarea
                          cols={30}
                          rows={8}
                          placeholder="Order Notes (Optional)"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="checkoutPaymentSection">
                    <div className="checkoutTotalContainer">
                      <h3>Your Order</h3>
                      <div className="checkoutItems">
                        <table>
                          <thead>
                            <tr>
                              <th>PRODUCTS</th>
                              <th>SUBTOTALS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((items) => (
                              <tr>
                                <td>
                                  {items.productId?.title} x {items.quantity}
                                  {items?.productId?.variants?.length > 1 && (
                                    <p>{items.variantId?.title}</p>
                                  )}
                                </td>
                                <td>
                                  £{items.variantId?.price * items.quantity}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="checkoutTotal">
                        <table>
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>£{totalPrice.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <th>Shipping</th>
                              <td>£5</td>
                            </tr>
                            <tr>
                              <th>VAT</th>
                              <td>£11</td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>
                                £
                                {(totalPrice === 0
                                  ? 0
                                  : totalPrice + 16
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="checkoutPaymentContainer">
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Direct Bank Transfer"
                          defaultChecked
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Direct Bank Transfer</span>
                          <p>
                            Make your payment directly into our bank account.
                            Please use your Order ID as the payment
                            reference.Your order will not be shipped until the
                            funds have cleared in our account.
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Check Payments"
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Check Payments</span>
                          <p>
                            Phasellus sed volutpat orci. Fusce eget lore mauris
                            vehicula elementum gravida nec dui. Aenean aliquam
                            varius ipsum, non ultricies tellus sodales eu. Donec
                            dignissim viverra nunc, ut aliquet magna posuere
                            eget.
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Cash on delivery"
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Cash on delivery</span>
                          <p>
                            Phasellus sed volutpat orci. Fusce eget lore mauris
                            vehicula elementum gravida nec dui. Aenean aliquam
                            varius ipsum, non ultricies tellus sodales eu. Donec
                            dignissim viverra nunc, ut aliquet magna posuere
                            eget.
                          </p>
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="payment"
                          value="Paypal"
                          onChange={handlePaymentChange}
                        />
                        <div className="checkoutPaymentMethod">
                          <span>Paypal</span>
                          <p>
                            Phasellus sed volutpat orci. Fusce eget lore mauris
                            vehicula elementum gravida nec dui. Aenean aliquam
                            varius ipsum, non ultricies tellus sodales eu. Donec
                            dignissim viverra nunc, ut aliquet magna posuere
                            eget.
                          </p>
                        </div>
                      </label>
                      <div className="policyText">
                        Your personal data will be used to process your order,
                        support your experience throughout this website, and for
                        other purposes described in our{" "}
                        <Link to="/terms" onClick={scrollToTop}>
                          Privacy Policy
                        </Link>
                        .
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleTabClick("cartTab3");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setPayments(true);
                      }}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              )}

              {/* tab3 */}
              {activeTab === "cartTab3" && (
                <div className="orderCompleteSection">
                  <div className="orderComplete">
                    <div className="orderCompleteMessage">
                      <div className="orderCompleteMessageImg">
                        <img src={success} alt="" />
                      </div>
                      <h3>Your order is completed!</h3>
                      <p>Thank you. Your order has been received.</p>
                    </div>
                    <div className="orderInfo">
                      <div className="orderInfoItem">
                        <p>Order Number</p>
                        <h4>{orderNumber}</h4>
                      </div>
                      <div className="orderInfoItem">
                        <p>Date</p>
                        <h4>{formatDate(currentDate)}</h4>
                      </div>
                      <div className="orderInfoItem">
                        <p>Total</p>
                        <h4>£{totalPrice.toFixed(2)}</h4>
                      </div>
                      <div className="orderInfoItem">
                        <p>Payment Method</p>
                        <h4>{selectedPayment}</h4>
                      </div>
                    </div>
                    <div className="orderTotalContainer">
                      <h3>Order Details</h3>
                      <div className="orderItems">
                        <table>
                          <thead>
                            <tr>
                              <th>PRODUCTS</th>
                              <th>SUBTOTALS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((items) => (
                              <tr>
                                <td>
                                  {items.productId?.title} x {items.quantity}
                                  {items.productId?.variants?.length > 1 && (
                                    <p>Variant: {items.variantId?.title}</p>
                                  )}
                                </td>
                                <td>
                                  £{items.variantId?.price * items.quantity}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="orderTotal">
                        <table>
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>£{totalPrice.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <th>Shipping</th>
                              <td>£5</td>
                            </tr>
                            <tr>
                              <th>VAT</th>
                              <td>£11</td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>
                                £
                                {(totalPrice === 0
                                  ? 0
                                  : totalPrice + 16
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
};

export default ShoppingCart;
