import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";

import { Link } from "react-router-dom";

import "./Product.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { addProductToCart, productListOfCart } from "../../../Redux/cartSlice";
import useSnackbar from "../../../hooks/useSnackbar";
import SnackbarComponent from "../../Snackbar";

const Product = ({ productData }) => {
  const dispatch = useDispatch();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const { loading } = useSelector((state) => state.cart);
  const { user, isAuthenticated, logged } = useSelector((state) => state.auth);

  const { title, images, price, variants, tags, product_type, options } =
    productData;

  const productImg = useMemo(() => images || [], [images]);

  const [currentImg, setCurrentImg] = useState(0);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [currentVariant, setCurrentVariant] = useState(
    variants ? variants[0] : null
  );

  const findCurrentVariant = useCallback(() => {
    return variants.find((variant) => {
      return Object.keys(selectedVariants).every((optionName) => {
        const optionValue = selectedVariants[optionName];
        return variant.title === optionValue;
      });
    });
  }, [selectedVariants, variants]); // Dependencies

  useEffect(() => {
    if (variants && variants.length > 0) {
      const initialVariant = variants[0]; // Default to the first variant
      setCurrentVariant(initialVariant);

      // Automatically set selected options based on the first variant
      const initialOptions = {};
      options.forEach((option, index) => {
        initialOptions[option.name] = initialVariant[`option${index + 1}`]; // Set based on option naming (e.g., option1, option2)
      });
      setSelectedVariants(initialOptions);
    }
  }, [variants, options]);

  useEffect(() => {
    const variant = findCurrentVariant();
    setCurrentVariant(variant); // Set the current variant
    if (variant) {
      const imageIndex = productImg.findIndex((img) =>
        img.variant_ids.includes(variant.id)
      );
      setCurrentImg(imageIndex !== -1 ? imageIndex : 0); // Default to the first image if not found
    }
  }, [selectedVariants, variants, productImg, findCurrentVariant]);

  const prevImg = () => {
    setCurrentImg(currentImg === 0 ? productImg.length - 1 : currentImg - 1);
  };

  const nextImg = () => {
    setCurrentImg(currentImg === productImg.length - 1 ? 0 : currentImg + 1);
  };

  const increment = () => {
    if (currentVariant && quantity < currentVariant?.inventory_quantity) {
      setQuantity(quantity + 1);
    }
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value);
    if (currentVariant) {
      if (
        !isNaN(value) &&
        value >= 1 &&
        value <= currentVariant?.inventory_quantity
      ) {
        setQuantity(value);
      } else if (value > currentVariant?.inventory_quantity) {
        setQuantity(currentVariant?.inventory_quantity); // Limit to max stock
      } else {
        setQuantity(1); // Set minimum of 1
      }
    }
  };

  const handleAddToCart = () => {
    if (user.success && isAuthenticated && logged === "pass") {
      if (quantity > 0) {
        const productDetails = {
          productId: productData._id,
          variantId: currentVariant.id,
          quantity: quantity,
        };
        dispatch(addProductToCart(productDetails))
          .then(({ payload }) => {
            showSnackbar(
              payload.message || "Added to cart!",
              payload.success ? "success" : "error"
            );
            dispatch(productListOfCart());
          })
          .catch((error) => {
            showSnackbar(
              error.message || "Failed to added into cart!",
              "error"
            );
          });
      } else {
        showSnackbar("Please add quantity!", "warning");
      }
    } else {
      showSnackbar(
        "You must log in first to add products to your shopping cart!",
        "warning"
      );
    }
  };

  const handleChangeVariant = (variantName) => (event) => {
    setSelectedVariants((prev) => ({
      ...prev,
      [variantName]: event.target.value,
    }));
    setQuantity(0);
  };

  const handleImageClick = (index) => {
    setCurrentImg(index);

    const variant = variants.find((v) => {
      const image = productImg[index];
      return image.variant_ids.includes(v.id);
    });

    if (variant) {
      setCurrentVariant(variant);
      const newOptions = {};
      options.forEach((option, i) => {
        newOptions[option.name] = variant[`option${i + 1}`];
      });
      setSelectedVariants(newOptions);
    }
  };

  return (
    <div>
      <div className="productSection">
        <div className="productShowCase">
          {/* Product Slider --------------------- START------*/}
          <div className="productGallery">
            <div className="productThumb">
              {productImg.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  onClick={() => handleImageClick(index)}
                  alt={`Product ${index + 1}`}
                />
              ))}
            </div>
            <div className="productFullImg">
              <img src={productImg[currentImg]?.src} alt="product" />
              <div className="buttonsGroup">
                <button onClick={prevImg} className="directionBtn">
                  <GoChevronLeft size={18} />
                </button>
                <button onClick={nextImg} className="directionBtn">
                  <GoChevronRight size={18} />
                </button>
              </div>
            </div>
          </div>
          {/* Product Slider --------------------- END------*/}

          <div className="productDetails">
            <div className="productBreadcrumb">
              <div className="breadcrumbLink">
                <Link to="/">Home</Link>&nbsp;/&nbsp;
                <Link to="/shop">The Shop</Link>
              </div>
            </div>
            <div className="productName">
              <h1>{title || ""}</h1>
            </div>

            <div className="productPrice">
              <h3>£{currentVariant ? currentVariant.price : price}</h3>
            </div>

            {options?.length > 0 &&
              options.map((option) => (
                <div key={option.name} className="productVariant">
                  {option.values.length > 1 && (
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="demo-simple-select-filled-label">
                        {option.name}
                      </InputLabel>
                      <Select
                        labelId={`select-${option.name}-label`}
                        id={`select-${option.name}`}
                        value={selectedVariants[option.name] || ""}
                        onChange={handleChangeVariant(option.name)}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value="" disabled>
                          Select {option.name}
                        </MenuItem>
                        {option.values.map((value) => (
                          <MenuItem value={value} key={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              ))}

            <div className="productTags">
              <p>
                <span>SKU: </span>
                {currentVariant ? currentVariant.sku : "N/A"}
              </p>
              <p>
                <span>CATEGORIES: </span>
                {product_type || "N/A"}
              </p>
              <p>
                <span>TAGS: </span>
                {tags || "N/A"}
              </p>

              {currentVariant?.inventory_quantity > 0 && (
                <p>
                  <span style={{ textDecoration: "underline" }}>
                    ONLY {currentVariant?.inventory_quantity} LEFT!
                  </span>
                </p>
              )}
            </div>

            <div className="productCartQuantity">
              <div className="productQuantity">
                <button onClick={decrement}>-</button>
                <input
                  type="text"
                  value={quantity}
                  onChange={handleInputChange}
                />
                <button onClick={increment}>+</button>
              </div>
              <div className="productCartBtn">
                {currentVariant && currentVariant?.inventory_quantity > 0 ? (
                  <button onClick={handleAddToCart}>
                    {loading ? "Adding..." : "Add to Cart"}
                  </button>
                ) : (
                  <button className="btn-danger" disabled>
                    Sold Out
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
};

export default Product;
